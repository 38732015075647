<template>
	<div id="activeEdit" class="el-content">
		<a-form :label-col="{ span: 2 }" :wrapper-col="{ span: 8 }">
			<a-card title="分类选择" class="mb12">
				<a-form-item label="活动分类" required>
					<a-select v-model:value="form.class_id">
						<a-select-option v-for="(item,index) in typeData" :key="index" :value="item.id">
                            {{item.name}}
                        </a-select-option>
					</a-select>
				</a-form-item>
			</a-card>
			<a-card title="基本信息" class="mb12">
				<a-form-item label="活动名称" required>
					<a-input v-model:value="form.title"></a-input>
				</a-form-item>
		        <a-form-item label="活动人数" required>
                    <a-input v-model:value="form.max_number"></a-input>
		        </a-form-item>
		        <a-form-item label="活动logo" required>
		        	<kd-img-select :src="form.logo" @change="(url)=>{ form.logo = url }"></kd-img-select>
		        </a-form-item>
		        <a-form-item label="活动时间">
					<a-range-picker
						v-model:value="activity_time"
						:show-time="{ format: 'HH:mm' }"
						format="YYYY-MM-DD HH:mm"
					/>
		        </a-form-item>
				<a-form-item label="活动地点" required>
					<a-input v-model:value="form.address"></a-input>
				</a-form-item>
		        <a-form-item label="地址经纬度">
                    <div>
                        <kd-map :value="longitude+','+latitude" @change="getAddress"></kd-map>
                    </div>
                    <div class="f12">用于计算用户与该活动的位置距离</div>
		        </a-form-item>
		        <a-form-item label="是否推荐">
		        	<a-radio-group v-model:value="form.is_recommend">
		        		<a-radio :value="1">是</a-radio>
		        		<a-radio :value="0">否</a-radio>
		        	</a-radio-group>
		        </a-form-item>
		        <a-form-item label="排序">
		        	<a-input v-model:value="form.rank"></a-input>
		        </a-form-item>
			</a-card>
		    <a-card title="活动内容">
		        <div v-if="specLen == 0" @click="addcontent">
		            <div class="sac-add-content">
		                <p class="el-icon-plus"></p>
		            </div>
		        </div>
                <div v-else>
                    <div v-for="(item,index) in form.content.cont" :key='index' class="edit-spce">
                        <a-form-item :label="item.title">
                            <a-input
                                style="min-width:300px;"
                                type="textarea"
                                :autosize="{ minRows: 4, maxRows: 4}"
                                placeholder="请输入活动内容"
                                v-model:value="item.value">
                            </a-input>
                        </a-form-item>
                        <a-form-item label="图片展示">
                            <a-space>
                                <div>
                                    <kd-img-select :mutil="true" :src="item.img" @change="(url)=>{ item.img = url}"></kd-img-select>
                                </div>
                                <a-button style="margin-right:8px" type="primary" @click="reduceSpec(index)">
                                    <i class="ri-delete-bin-line"></i>
                                </a-button>
                                <a-button ype="primary" @click="modelForm.show = true">
                                    <i class="ri-add-line"></i>
                                </a-button>
                            </a-space>
                            
                        </a-form-item>
                    </div>
                </div>
		    </a-card>
		    <a-form-item  :wrapper-col="{ offset: 2 }">
                <a-space>
                    <a-button type="primary" @click="submitSave(0)">保存</a-button>
		    	    <a-button @click="$router.go(-1)">返回</a-button>
                </a-space>
		    </a-form-item>
		</a-form>
        <a-modal v-model:visible="modelForm.show" title="添加模块" @ok="addcontent" @cancel="modelForm.show = false" width="400px">
            <a-input v-model:value="modelForm.name" placeholder="添加模块" />
        </a-modal>
	</div>
</template>
<script>
import moment from "moment"
import { reactive, toRefs } from 'vue';
import activeModel from '@/api/addons/active';
import { useRoute } from 'vue-router';
export default {
    setup(){
        const state = reactive({
             form: {
                id:0,
                title: '',
                logo: '',
                address: '',
                latlng: '',
                rank: 99,
                content:{
                    detail:'',
                    cont:[
                        {title:'活动内容',value:'',img:[]}
                    ]
                },
                activity_start_time: '',  //活动开始时间
                activity_end_time: '',  //活动结束时间
                max_number: '',
                is_recommend: 1,
                class_id: ''
            },
            typeData: [],
            is_draft: 0,  //是否存为草稿
            activity_time: null,
            latitude: '',
            longitude: '',
            specLen: 1,
            modelForm:{
                name:'',
                show:false,
            }
        })
        const opt = useRoute().query
        activeModel.getActiveType(1,999,res=>state.typeData = res.list)

        if( opt.id ){
            activeModel.getActiveDetail(opt.id,Object.keys(state.form),res=>{
                state.form = res
                state.activity_time = [moment(res.activity_start_time),moment(res.activity_end_time)]
                let la = res.latlng.split(',')
                state.latitude = la[0]
                state.longitude = la[1]
            })
        }

        function addcontent(){
            state.form.content.cont.push({title:state.modelForm.name,value:'',img:[]});
            state.specLen = state.form.content.cont.length
            state.modelForm.name=""
            state.modelForm.show = false
        }

        function reduceSpec(index){
            state.form.content.cont.splice(index, 1);
            state.specLen = state.form.content.cont.length
        }

        function getAddress(e){
			state.latitude = e.latitude
			state.longitude = e.longitude
			state.form.latlng = e.latitude+','+e.longitude
		}

        const submitSave = ()=>{
            let form = JSON.parse(JSON.stringify(state.form))
            form.is_draft = state.is_draft
            form.activity_start_time = moment( state.activity_time[0] ).format("YYYY-MM-DD HH:mm")
			form.activity_end_time = moment(state.activity_time[1]).format("YYYY-MM-DD HH:mm")
            activeModel.addOrEditActive(form)
        }

        return{
            ...toRefs(state),
            submitSave,
            addcontent,
            reduceSpec,
            getAddress
        }
    }
};
</script>

<style lang="scss">
#activeEdit{
    width: 100%;
    .sac-add-content{
        margin-left: 80px;
        width: 180px;
        height: 100px;
        text-align: center;
        border: 2px solid #eee;
        cursor: pointer;

        >p{
            font-size: 32px;
            font-weight: bold;
        }

    }

    .kd-active-edit-btn{
        position: absolute;
        margin-left: 60%;
        z-index: 99;
    }
}
</style>
